<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="breadcrumb-bg"
      style="background-image: url(../assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">會員中心</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                基本資料
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-6 py-md-8">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-lg-4">
            <div class="card bg-warning card-hover mb-3">
              <div class="card-body text-center px-md-5 px-lg-6 my-2">
                <div class="card-icon-border-large border-warning mtn-80 member__img">
                  <img :src="previewImg" alt="" v-if="previewImg">
                  <img :src="form.icon" alt="" v-if="form.icon">
                  <img src="assets/img/avator/avator-img1.jpg" alt="testimonial1.jpg" v-if="previewImg === '' && form.icon === '' || form.icon === null">
                </div>
                <blockquote class="blockquote blockquote-sm mt-2">
                  <label class="btn btn-link">
                    <input id="file" type="file" @change="uploadFile">
                    <!-- <i class="fa fa-plus-circle"></i> <span>上傳圖片</span> -->
                  </label>

                  <footer
                    class="blockquote-footer text-uppercase text-white font-size-20 mb-3"
                  >
                    {{ gradeTitle }}
                  </footer>
                  <ul class="member__level-buttons">
                    <li v-for="(item, index) in gradeMap" :key="index" class="m-1">
                      <button @click="$router.push(item.link)" :disabled="form.grade < item.grade" type="button" class="btn btn-light mb-1">
                        {{ `${item.title}測驗` }}
                      </button>
                    </li>
                  </ul>
                </blockquote>
              </div>
            </div>

            <ul class="list-unstyled mb-0">
              <li class="mb-2">
                <router-link to="/member"
                  class="text-warning font-weight-medium d-block border rounded py-2 pl-3">
                  基本資料
                </router-link>
              </li>
              <li class="my-2">
                <router-link :to="grade === 0? '/fqtest' : '/member/myfqage'"
                  class="text-muted font-weight-medium d-block border rounded py-2 pl-3">
                  我的FQ年齡
                </router-link>
              </li>
              <li class="my-2">
                <router-link to="/member/mypoints"
                  class="text-muted font-weight-medium d-block border rounded py-2 pl-3">
                  我的$幣存摺
                </router-link>
              </li>
              <li class="my-2">
                <router-link to="/member/mall"
                  class="text-muted font-weight-medium d-block border rounded py-2 pl-3">
                  $幣兌換中心
                </router-link>
              </li>
              <li class="my-2">
                <router-link to="/member/exchange"
                  class="text-muted font-weight-medium d-block border rounded py-2 pl-3">
                  $幣累兌明細
                </router-link>
              </li>
              <li class="my-2">
                <button type="button"
                  @click="logOut"
                  class="btn btn-block text-muted font-weight-medium  border rounded py-2 pl-3">
                  會員登出
                </button>
              </li>
            </ul>
          </div>
          <div class="col-md-8 col-lg-8 order-md-1">
            <form class="" action="index.html" method="post">
              <div class="card shadow-none">
                <div
                  class="card-header card-header-lg bg-warning text-white p-3 rounded-top"
                >
                  <h4 class="font-weight-bold mb-0">基本資料</h4>
                </div>

                <div
                  class="card-body border border-top-0 rounded-bottom-sm p-7"
                >
                  <div class="row">
                    <div class="col-12">
                      <label for="username">姓名</label>
                      <div class="form-group form-group-icon">
                        <i class="fa fa-user"></i>
                        <input
                          v-model="form.name"
                          type="text"
                          class="form-control"
                          placeholder="你的名字"
                          required=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label for="nickname">暱稱</label>
                      <div class="form-group form-group-icon">
                        <i class="fa fa-user-circle-o"></i>
                        <input
                          v-model="form.nickname"
                          type="text"
                          class="form-control"
                          placeholder="暱稱"
                          required=""
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <label for="email">登入Email</label>
                      <div class="form-group form-group-icon">
                        <i class="fa fa-envelope"></i>
                        <input
                          v-model="form.email"
                          type="email"
                          class="form-control"
                          placeholder="Yourmail@gmail.com"
                          required=""
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <label for="mobile">手機號碼</label>
                      <div class="form-group form-group-icon">
                        <i class="fa fa-phone" aria-hidden="true"></i>
                        <input
                          v-model="form.mobile"
                          type="text"
                          class="form-control"
                          placeholder="0912345678"
                          required=""
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <label for="adds">詳細地址</label>
                      <div class="form-group form-group-icon">
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                        <input
                          v-model="form.address"
                          type="text"
                          class="form-control"
                          placeholder="台北市中山區愛國東路100號"
                          required=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-12">
                      <label for="first-name">性別</label>
                      <select
                        v-model="form.sex"
                        class="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option value="M">男</option>
                        <option value="F">女</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-lg-4">
                      <label for="first-name">出生年</label>
                      <input
                        v-model="form.birth_year"
                        type="text"
                        class="form-control">
                      <!-- <select
                        class="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>1999</option>
                        <option>1998</option>
                      </select> -->
                    </div>

                    <div class="col-lg-4">
                      <label for="first-name">月</label>
                      <select
                        v-model="form.birth_month"
                        class="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>01</option>
                        <option>02</option>
                        <option>03</option>
                        <option>04</option>
                        <option>05</option>
                        <option>06</option>
                        <option>07</option>
                        <option>08</option>
                        <option>09</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                      </select>
                    </div>

                    <div class="col-lg-4">
                      <label for="first-name">日</label>
                      <select
                        v-model="form.birth_day"
                        class="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>01</option>
                        <option>02</option>
                        <option>03</option>
                        <option>04</option>
                        <option>05</option>
                        <option>06</option>
                        <option>07</option>
                        <option>08</option>
                        <option>09</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                        <option>13</option>
                        <option>14</option>
                        <option>15</option>
                        <option>16</option>
                        <option>17</option>
                        <option>18</option>
                        <option>19</option>
                        <option>20</option>
                        <option>21</option>
                        <option>22</option>
                        <option>23</option>
                        <option>24</option>
                        <option>25</option>
                        <option>26</option>
                        <option>27</option>
                        <option>28</option>
                        <option>29</option>
                        <option>30</option>
                        <option>31</option>
                      </select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <label for="username">密碼</label>
                      <div class="form-group form-group-icon">
                        <i class="fa fa-unlock" aria-hidden="true"></i>
                        <input
                          v-model="form.password"
                          type="password"
                          class="form-control"
                          placeholder="******"
                          required=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label for="username">確認密碼</label>
                      <div class="form-group form-group-icon">
                        <i class="fa fa-lock" aria-hidden="true"></i>
                        <input
                          v-model="checkPassword"
                          type="password"
                          class="form-control"
                          placeholder="******"
                          required=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ul class="list-unstyled d-flex justify-content-center mt-4">
                <li>
                  <button @click="doneMemberDetail" type="button" class="btn btn-danger text-white text-uppercase px-10">儲存修改</button>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import tokenGenerator from '@/tokenGenerator.js'
import richkidConfig from '@/assets/json/richkid.config.json'
import blobtoDataURL from '@/blobtoDataURL.js'
export default {
  name: 'Member',
  data () {
    return {
      checkPassword: '',
      form: {},
      gradeTitle: '',
      grade: '',
      previewImg: '',
      getImgUrl: ''
    }
  },
  computed: {
    gradeMap () {
      return richkidConfig.data.grade
    }
  },
  created () {
    const vm = this
    const memberToken = localStorage.getItem('memberToken')
    const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/check`

    if (memberToken === '') {
      alert('請先登入')
      vm.$router.push('/login')
      return false
    } else {
      vm.axios.post(api, {
        token: tokenGenerator(),
        memberToken
      })
        .then((res) => {
          if (res.data.err_msg === 'token已失效') {
            alert('請重新登入')
            vm.$router.push('/login')
          }
        })
    }

    vm.getMemberDetail()
  },
  mounted () {
    require('@/assets/js/base.js')
  },
  methods: {
    getMemberDetail () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/check`

      const memberToken = localStorage.getItem('memberToken')

      vm.axios.post(api, {
        token: tokenGenerator(),
        memberToken
      })
        .then((res) => {
          if (res.data.status === '003') {
            alert('請重新登入')
            this.$router.push('/login')
            localStorage.removeItem('memberToken')
            return null
          }
          vm.form = res.data.content
          localStorage.setItem('reloginImg', vm.form.icon)
          const { grade } = res.data.content
          vm.grade = grade
          vm.gradeTitle = vm.gradeMap[grade].title
          sessionStorage.setItem('gradeTitle', vm.gradeTitle)
        })
    },
    updateMemberDetail () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/memberEdit`

      vm.form.birthday = `${vm.form.birth_year}-${vm.form.birth_month}-${vm.form.birth_day}`

      vm.axios.post(api, { ...vm.form, token: tokenGenerator(), icon: vm.getImgUrl })
        .then((res) => {
          if (res.data.status === '000') {
            alert('修改成功')
          }
        })
    },
    uploadFile () {
      const vm = this
      const fileImg = document.querySelector('#file').files[0]

      if (!fileImg) {
        return null
      } else {
        if ((fileImg.size / 1024) > 500) {
          alert('圖片大小不能超過 500 KB')
          document.querySelector('#file').value = ''
        } else {
          blobtoDataURL(fileImg, dataURL => {
            vm.previewImg = 'data:image/png;base64,' + dataURL.split(',')[1]
          })
          vm.form.icon = ''
        }
      }
    },
    logOut () {
      localStorage.removeItem('token')
      localStorage.removeItem('memberId')
      localStorage.removeItem('memberToken')
      localStorage.removeItem('reloginImg')

      location.href = '/'
    },
    editImg () {
      return new Promise((resolve, reject) => {
        const vm = this
        const api = 'https://apitemplate.moneybar.com.tw/public/api/UploadFile/img'

        vm.axios.post(api, {
          token: tokenGenerator(),
          member_id: localStorage.getItem('memberId'),
          path: 'iPlay/member/icon/',
          size: 200,
          img: vm.previewImg
        })
          .then((res) => {
            // 成功的話，status 會是 000
            console.log(res.data.content.imgurl)
            vm.getImgUrl = res.data.content.imgurl
            vm.previewImg = ''
            vm.getMemberDetail()
            resolve()
          })
      })
    },
    doneMemberDetail () {
      const vm = this

      if (vm.previewImg === '') {
        vm.updateMemberDetail()
      } else {
        vm.editImg().then((res) => {
          vm.updateMemberDetail()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.member__level-buttons {
  padding-left: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 1rem 0;
}
.member {
  &__img {
    img {
      width: 138px;
      height: 138px;
      object-fit: cover;
    }
  }
}
</style>
