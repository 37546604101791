/**
 * Generate a blob into base64
 * @param {blob} blob blob to be input
 * @param {function} callback callback fn to be invoked when generate is okay
 * @returns {string} base64 format string
 */
function blobtoDataURL (blob, callback) {
  var fr = new FileReader()
  fr.onload = function (e) {
    callback(e.target.result)
  }
  fr.readAsDataURL(blob)
}

export default blobtoDataURL
